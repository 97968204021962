import type { AxiosError, InternalAxiosRequestConfig } from 'axios'
import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'
import qs from 'qs'

import { QS_CONFIG } from '../../constants/api'
import { addCredentialsToRequest } from './addCredentialsToRequest'
import { handleFetchError } from './fetchError'
import { getApiOrigin } from './getApiOrigin'
import { refreshAuthToken } from './refreshAuthToken'

export type RequestPath = `/${string}`

type RequestBody = Record<string, unknown>

const DEFAULT_HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
  "Accept": 'application/json',
}

axios.interceptors.request.use(
  (req: InternalAxiosRequestConfig) => addCredentialsToRequest(req),
  (error: AxiosError) => error,
)

axios.interceptors.response.use(
  res => res,
  (error: AxiosError) => refreshAuthToken(error),
)

// There is no trycatch because react-query will handle it
async function http<ResponseData, RequestData>(
  path: RequestPath,
  axiosRequestConfig: AxiosRequestConfig<RequestData>,
) {
  const headers = {
    ...DEFAULT_HEADERS,
    ...(axiosRequestConfig?.headers ? axiosRequestConfig.headers : {}),
  }

  // Remove undefined headers
  Object.keys(headers).forEach(key => {
    const headerKey = key as keyof typeof headers
    if (!headers[headerKey]) {
      delete headers[headerKey]
    }
  })

  const config = {
    ...axiosRequestConfig,
    url: path,
    headers,
  }

  const axiosRequest: AxiosRequestConfig<RequestData> = {
    baseURL: getApiOrigin(config),
    ...config,
  }

  const axiosResponse: AxiosResponse<ResponseData, RequestData> = await axios(
    axiosRequest,
  )

  handleFetchError<RequestData>(
    axiosResponse as unknown as AxiosError,
    axiosRequest,
  )

  return axiosResponse
}

async function get<ResponseData>(
  path: RequestPath,
  params?: Record<string, unknown>,
  axiosRequestConfig?: AxiosRequestConfig<string>,
) {
  let stringParams = ''
  if (params) {
    // Type assertion is added here so that we can still pass number | string
    // using Record<string, unknown> in GetArgs
    stringParams = '?' + new URLSearchParams(params as Record<string, string>).toString()
  }
  return await http<ResponseData, string>(`${path}${stringParams}`, {
    method: 'GET',
    ...axiosRequestConfig,
  })
}

async function post<ResponseData>(
  path: RequestPath,
  body?: RequestBody,
  axiosRequestConfig?: AxiosRequestConfig<string>,
) {
  return await http<ResponseData, string>(path, {
    method: 'POST',
    data: qs.stringify(body, QS_CONFIG),
    ...axiosRequestConfig,
  })
}

async function put<ResponseData>(
  path: RequestPath,
  body?: RequestBody,
  axiosRequestConfig?: AxiosRequestConfig<string>,
) {
  return await http<ResponseData, string>(path, {
    method: 'PUT',
    data: qs.stringify(body, QS_CONFIG),
    ...axiosRequestConfig,
  })
}

async function deleteMethod<ResponseData>(
  path: RequestPath,
  axiosRequestConfig?: AxiosRequestConfig<string>,
) {
  return await http<ResponseData, string>(path, {
    method: 'DELETE',
    ...axiosRequestConfig,
  })
}

async function patch<ResponseData>(
  path: RequestPath,
  body?: RequestBody,
  axiosRequestConfig?: AxiosRequestConfig<string>,
) {
  return await http<ResponseData, string>(path, {
    method: 'PATCH',
    data: qs.stringify(body, QS_CONFIG),
    ...axiosRequestConfig,
  })
}

async function uploadMedia<ResponseData>(
  path: RequestPath,
  body: RequestBody,
  axiosRequestConfig?: AxiosRequestConfig<FormData>,
) {
  const formData = new FormData()
  for (const key in body) {
    if (Array.isArray(body[key])) {
      ;(body[key] as File[]).forEach(file => {
        formData.append(`${key}[]`, file)
      })
    } else {
      formData.append(key, body[key] as string | Blob)
    }
  }
  return await http<ResponseData, FormData>(path, {
    method: 'POST',
    data: formData,
    // We need to have empty headers for using formdata in media upload
    // See this article: https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
    headers: undefined,
    ...axiosRequestConfig,
  })
}

async function getAdvertisePro<ResponseData>(
  path: RequestPath,
  params?: Record<string, unknown>,
  axiosRequestConfig?: AxiosRequestConfig<string>,
) {
  let stringParams = ''
  if (params) {
    // Type assertion is added here so that we can still pass number | string
    // using Record<string, unknown> in GetArgs
    stringParams = '?' + new URLSearchParams(params as Record<string, string>).toString()
  }
  return await http<ResponseData, string>(`${path}${stringParams}`, {
    method: 'GET',
    baseURL: import.meta.env.VITE_ADVERTISE_PRO_URL,
    ...axiosRequestConfig,
  })
}

export const api = {
  http,
  get,
  post,
  put,
  delete: deleteMethod,
  patch,
  uploadMedia,
  getAdvertisePro,
}
