import axios from 'axios'
import { useEffect } from 'react'

import { useBoundStore } from '../store/store'
import type { ApiResponse } from '../types/api'
import { api } from '../utils/api-client'

interface DepositEvent {
  account: string
  amount: string
  bank_account: string
  bank_account_name: string
  billno: string
  cardID: string
  checkPerson: string
  depStatus: string
  endTime: string
  fee: string
  for_bankverify: string
  id: string
  payType: string
  platInfo: string
  requestTime: string
  saveName: string
  saveTime: string
  userType: string
  is_first_deposit: '1' | '0'
}

const useDepositEvents = () => {
  const username = useBoundStore(state => state.user.username)
  const email = useBoundStore(state => state.user.email)
  const phone = useBoundStore(state => state.user.phone)

  useEffect(() => {
    if (!username) return
    const controller = new AbortController()
    const signal = controller.signal

    const getDepositEvents = async () => {
      try {
        const res = await api.post<ApiResponse<DepositEvent[]>>(
          '/conduct.php',
          {
            type: 'get_new_successful_deposits',
          },
          { signal }
        )
        const { status, info } = res.data
        if (status === 1 && info.length >= 1) {
          info.forEach(depositEvent => {
            // Twitter
            twq('event', 'tw-olnlq-olnmr', {
              value: depositEvent.amount,
              currency: 'MXN',
              contents: [depositEvent],
              email_address: email,
              phone_number: `+52${phone}`,
            })
          })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error)
        }
      }
    }

    getDepositEvents()

    return () => {
      controller.abort()
    }
  }, [username])
}

export default useDepositEvents
