// Convert a number to a string with commas
export function numberWithCommas(x: number | string, fixedDecimals?: boolean) {
  const number = Number(x).toString().split('.')
  if (number[1]) {
    return (
      number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      '.' +
      number[1].slice(0, 2)
    )
  }
  return (
    number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    (fixedDecimals ? '.00' : '')
  )
}

export function generateRandomInteger(max: number) {
  return Math.floor(Math.random() * max) + 1
}

export function clamp(num: number, max: number) {
  return num > max ? max : num
}

export function generateRandomNumber(min: number, max: number) {
  const difference = max - min

  // generate random number 
  let rand = Math.random()

  // multiply with difference 
  rand = rand * difference

  // add with min value 
  rand = rand + min

  return rand
}
