import classNames from '../../utils/classNames'

interface BackdropProps {
  onClick?: () => void
  isVisible: boolean
  id?: string
  zIndex?: number
}

const Backdrop = ({ onClick, isVisible, id, zIndex = 18 }: BackdropProps) => {
  return (
    <div
      className={classNames(
        'fixed z-[18] w-screen h-screen bg-[rgba(32,38,50,0.7)] transition-all duration-[0.3s] ease-[ease-in-out] animate-[fadeIn_0.3s_ease-in-out] left-0 top-0',
        isVisible
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none',
      )}
      style={{ zIndex }}
      id={id ?? 'backdrop'}
      onClick={onClick}
    />
  )
}

export default Backdrop
