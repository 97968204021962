import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useEffect } from 'react'
import { Portal } from 'react-portal'

import classNames from '../../utils/classNames'
import closeModal from '../../utils/closeModal'
import Icon from '../icon'

interface ToastMessageProps {
  text: string
  isValid?: boolean
}

const ToastMessage = ({ text, isValid }: ToastMessageProps) => {
  const modal = useModal()

  useEffect(() => {
    if (modal.visible) {
      setTimeout(() => closeModal(modal), 3000)
    }
  }, [modal.visible])

  return (
    <Portal>
      <p
        className={classNames(
          'text-[#202632] fixed bg-[#eee] z-[225] transition-all duration-[0.2s] ease-[ease-in-out] flex items-start text-sm w-max max-w-[calc(100vw_-_40px)] px-[15px] py-2.5 -translate-x-2/4 rounded-[5px] left-2/4 top-5 xl:text-xl xl:px-[17px] xl:py-3',
          modal.visible
            ? 'opacity-100 pointer-events-auto -translate-x-2/4 translate-y-0'
            : 'opacity-0 pointer-events-none -translate-y-5',
        )}>
        {isValid && (
          <Icon
            className="min-w-[15px] mr-[7px] mt-1 xl:min-w-[20px] xl:mr-2.5"
            id="check-2"
            width="15"
            height="11"
            fill="#202632"
          />
        )}
        {isValid === false && (
          <Icon
            className="min-w-[15px] mr-[7px] mt-1 xl:min-w-[20px] xl:mr-2.5"
            id="close-2"
            width="20"
            height="20"
            fill="#202632"
            opacity="0.8"
          />
        )}
        {text}
      </p>
    </Portal>
  )
}

export default NiceModal.create(ToastMessage)
